var exports = {};
exports = {
  "0": "25",
  "1": "112",
  "2": "113",
  "3": "114",
  "4": "115",
  "5": "116",
  "6": "117",
  "7": "118",
  "8": "119",
  "9": "120",
  A: "10",
  B: "11",
  C: "12",
  D: "7",
  E: "8",
  F: "9",
  G: "15",
  H: "80",
  I: "126",
  J: "4",
  K: "6",
  L: "13",
  M: "14",
  N: "16",
  O: "17",
  P: "18",
  Q: "79",
  R: "81",
  S: "83",
  T: "84",
  U: "85",
  V: "86",
  W: "87",
  X: "88",
  Y: "89",
  Z: "90",
  a: "91",
  b: "92",
  c: "93",
  d: "94",
  e: "95",
  f: "96",
  g: "97",
  h: "98",
  i: "99",
  j: "100",
  k: "101",
  l: "102",
  m: "103",
  n: "104",
  o: "105",
  p: "106",
  q: "107",
  r: "108",
  s: "109",
  t: "110",
  u: "111",
  v: "20",
  w: "21",
  x: "22",
  y: "23",
  z: "24",
  AB: "121",
  BB: "122",
  CB: "123",
  DB: "124",
  EB: "125",
  FB: "5",
  GB: "19",
  HB: "26",
  IB: "27",
  JB: "28",
  KB: "29",
  LB: "30",
  MB: "31",
  NB: "32",
  OB: "33",
  PB: "34",
  QB: "35",
  RB: "36",
  SB: "37",
  TB: "38",
  UB: "39",
  VB: "40",
  WB: "41",
  XB: "42",
  YB: "43",
  ZB: "44",
  aB: "45",
  bB: "46",
  cB: "47",
  dB: "48",
  eB: "49",
  fB: "50",
  gB: "51",
  hB: "52",
  iB: "53",
  jB: "54",
  kB: "55",
  lB: "56",
  mB: "57",
  nB: "58",
  oB: "60",
  pB: "62",
  qB: "63",
  rB: "64",
  sB: "65",
  tB: "66",
  uB: "67",
  vB: "68",
  wB: "69",
  xB: "70",
  yB: "71",
  zB: "72",
  "0B": "73",
  "1B": "74",
  "2B": "75",
  "3B": "76",
  "4B": "77",
  "5B": "78",
  "6B": "127",
  "7B": "11.1",
  "8B": "12.1",
  "9B": "15.5",
  AC: "16.0",
  BC: "17.0",
  CC: "18.0",
  DC: "3",
  EC: "59",
  FC: "61",
  GC: "82",
  HC: "128",
  IC: "129",
  JC: "3.2",
  KC: "10.1",
  LC: "15.2-15.3",
  MC: "15.4",
  NC: "16.1",
  OC: "16.2",
  PC: "16.3",
  QC: "16.4",
  RC: "16.5",
  SC: "17.1",
  TC: "17.2",
  UC: "17.3",
  VC: "17.4",
  WC: "17.5",
  XC: "17.6",
  YC: "11.5",
  ZC: "4.2-4.3",
  aC: "5.5",
  bC: "2",
  cC: "130",
  dC: "131",
  eC: "3.5",
  fC: "3.6",
  gC: "3.1",
  hC: "5.1",
  iC: "6.1",
  jC: "7.1",
  kC: "9.1",
  lC: "13.1",
  mC: "14.1",
  nC: "15.1",
  oC: "15.6",
  pC: "16.6",
  qC: "TP",
  rC: "9.5-9.6",
  sC: "10.0-10.1",
  tC: "10.5",
  uC: "10.6",
  vC: "11.6",
  wC: "4.0-4.1",
  xC: "5.0-5.1",
  yC: "6.0-6.1",
  zC: "7.0-7.1",
  "0C": "8.1-8.4",
  "1C": "9.0-9.2",
  "2C": "9.3",
  "3C": "10.0-10.2",
  "4C": "10.3",
  "5C": "11.0-11.2",
  "6C": "11.3-11.4",
  "7C": "12.0-12.1",
  "8C": "12.2-12.5",
  "9C": "13.0-13.1",
  AD: "13.2",
  BD: "13.3",
  CD: "13.4-13.7",
  DD: "14.0-14.4",
  ED: "14.5-14.8",
  FD: "15.0-15.1",
  GD: "15.6-15.8",
  HD: "16.6-16.7",
  ID: "all",
  JD: "2.1",
  KD: "2.2",
  LD: "2.3",
  MD: "4.1",
  ND: "4.4",
  OD: "4.4.3-4.4.4",
  PD: "5.0-5.4",
  QD: "6.2-6.4",
  RD: "7.2-7.4",
  SD: "8.2",
  TD: "9.2",
  UD: "11.1-11.2",
  VD: "12.0",
  WD: "13.0",
  XD: "14.0",
  YD: "15.0",
  ZD: "19.0",
  aD: "14.9",
  bD: "13.52",
  cD: "2.5",
  dD: "3.0-3.1"
};
export default exports;